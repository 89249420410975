import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import LastUsed from "locize-lastused";
import { locizePlugin } from "locize";
const locizeOptions = {
  projectId: "3f59c497-f941-4ebc-ba39-7dfb971f93da",
  apiKey: "b6bed5a4-cec0-4217-a180-3f3c519a068a", // YOU should not expose your apps API key to production!!!
  referenceLng: "en",
};

(i18n as any)
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  .use(LastUsed)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin) // will show the incontext editor only if passing ?incontext=true
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    saveMissing: true,
    defaultNS: "main",
    ns: ["main"],
    // keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: "languageChanged editorSaved",
    },
  });
i18n.on("languageChanged", () => {
  console.log(i18n.language, i18n);
  document.documentElement.lang = i18n.language;
});
export default i18n;
