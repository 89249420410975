import { FC, KeyboardEventHandler, useCallback, useState } from "react";
import "./input.css";
import { json } from "stream/consumers";
import { useGetData } from "./hook";
import { useTranslation } from "react-i18next";
interface InputType {
  onClick: () => void;
  setAnswer: (answer: string) => void;
  setImage: (url: string) => void;
}
export const Input: FC<InputType> = ({ onClick, setAnswer, setImage }) => {
  const [text, setText] = useState("");
  const textLen = text.length;
  const { click } = useGetData({ setAnswer, setImage, text });
  const onEnterPress = (event: {
    keyCode: number;
    shiftKey: boolean;
    preventDefault: () => void;
  }) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      onClick();
      click();
    }
  };

  const { t, i18n } = useTranslation();
  return (
    <div className="searchBox">
      <textarea
        value={text}
        onKeyDown={onEnterPress}
        onChange={(e) => setText(e.target.value)}
        className="searchInput"
        name=""
        onSubmitCapture={() => {
          onClick();
          click();
        }}
        placeholder={t("briefly", "tell me briefly...")}
        maxLength={512}
      ></textarea>
      <button
        onClick={() => {
          onClick();
          // debugger;
          click();
        }}
        className={`searchButton ${textLen <= 369 ? "" : "alarm"}`}
        style={{
          background:
            textLen <= 369
              ? `rgba(${(textLen / 369) * 255},0,${(textLen / 369) * 255},${
                  textLen / 369
                })`
              : "red",
        }}
      >
        {textLen}.369
      </button>
    </div>
  );
};
