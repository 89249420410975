import { FC, useEffect, useRef } from "react";
import "./bg.css";
interface BackgroundType {
  load: boolean;
}
export const Background: FC<BackgroundType> = ({ load: loadCur }) => {
  const loadRef = useRef(loadCur);
  loadRef.current = loadCur;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    let width = canvas.width;
    let height = canvas.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    let paralax = { x: 0, y: 0 };
    window.document.body.addEventListener(
      "mousemove",
      (event) => {
        paralax.x = ((width - event.clientX) / width) * -2 + 1;
        paralax.y = ((height - event.clientY) / height) * -2 + 1;
      },
      false
    );
    window.addEventListener("resize", resizeCanvas, false);

    const halfScrean =
      Math.min(height / 2, width / 2) * window.devicePixelRatio;
    type Dot = {
      x: number;
      y: number;
      xs: number;
      ys: number;
      time: number;
      z: number;
    };
    const spawnPoint = (start = false) => {
      const random = Math.random();
      const randomSpeed = Math.random() * 3 + 1;
      let randomStart = 2;
      if (start) {
        randomStart = 1 + Math.random() * 10;
      }
      const x = Math.sin(random * Math.PI * 2) * 10;
      const y = Math.cos(random * Math.PI * 2) * 10;
      return {
        x: ((x * randomStart) / 100) * halfScrean + width / 2,
        y: ((y * randomStart) / 100) * halfScrean + height / 2,
        xs: (x * randomSpeed) / 100,
        ys: (y * randomSpeed) / 100,
        time: 0,
        z: Math.random() * 1 + 1,
      } as Dot;
    };

    let dots: Dot[] = [...new Array(400)].map(() => spawnPoint(true));

    let loadProc = 0;
    function step(timeStamp: any) {
      if (!ctx) {
        return;
      }
      ctx.fillStyle = "#000";

      ctx.clearRect(0, 0, width, height);
      ctx.fillRect(0, 0, width, height);

      dots = dots.map((dot) => {
        const { xs, ys, time, x: xdot, y: ydot, z } = dot;
        if (time < 100) {
          dot.time++;
        }
        const x = xdot + Math.abs(xs * ys) * paralax.x * z * -20;
        const y = ydot + Math.abs(xs * ys) * paralax.y * z * -20;

        dot.xs *= 1.002;
        dot.ys *= 1.002;
        dot.x += xs * (loadProc / 20 + 0.5);
        dot.y += ys * (loadProc / 20 + 0.5);
        ctx.fillStyle = `rgba(255,255,255,${Math.min(time / 100, 1)})`;
        ctx.lineWidth = (loadProc / 420) * 3 + 1 * z * Math.min(time / 100, 1);
        ctx.strokeStyle = ctx.fillStyle;
        ctx.beginPath();
        ctx.ellipse(
          x + xs * loadProc * 3,
          y + ys * loadProc * 3,
          0.5 * z,
          0.5 * z,
          0,
          0,
          2 * Math.PI
        );
        ctx.moveTo(x - xs * 0, y - ys * 0);

        ctx.lineTo(x + xs * loadProc * 3, y + ys * loadProc * 3);

        ctx.fill();
        ctx.stroke();
        ctx.closePath();
        if (x > width || x < 0 || y > height || y < 0) {
          spawnPoint();
          return spawnPoint();
        }
        return dot;
      });
      const load = loadRef.current;
      if (load && loadProc < 200) {
        if (loadProc < 1) {
          loadProc = 1;
        }
        loadProc *= 1.1;
      }
      if (!load && loadProc > 0) {
        loadProc /= 1.05;
      }
      window.requestAnimationFrame(step);
    }

    function resizeCanvas() {
      if (!canvas) {
        return;
      }

      dots.forEach((dot, i) => {
        const { x, y } = dot;
        dot.x = (x / width) * window.innerWidth * window.devicePixelRatio;
        dot.y = (y / height) * window.innerHeight * window.devicePixelRatio;
      });

      canvas.width = window.innerWidth * window.devicePixelRatio;
      canvas.height = window.innerHeight * window.devicePixelRatio;
      width = canvas.width;
      height = canvas.height;
      /**
       * Your drawings need to be inside this function otherwise they will be reset when
       * you resize the browser window and the canvas goes will be cleared.
       */
    }

    resizeCanvas();

    window.requestAnimationFrame(step);
  }, []);
  return (
    <div className="bgCont">
      <canvas className="bg" ref={canvasRef} />;
    </div>
  );
};
