import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Input } from "./ui/input/input";
import { Lunarphase } from "./ui/lunarphase/lunarphase";
import { Background } from "./ui/bg/bg";
import { Answer } from "./ui/answer/answer";
import { useAddToHomescreenPrompt } from "./hooks/addToHomeScreen";
import { useTranslation, Trans } from "react-i18next";
import nlp from "compromise";

// Входное предложение
const sentence = "Это интересная книга.";

// Анализ предложения и выделение существительных
const nouns = nlp(sentence).nouns().out("array");

// Вывод найденных существительных
console.log(nouns);
function App() {
  const content = useRef<HTMLDivElement>(null);
  const [load, setLoad] = useState(false);
  const [image, setImage] = useState("");
  const [answer, setAnswer] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    const div = content.current;
    if (answer.length) {
      if (div) {
        div.style.opacity = "1";
      }
      setLoad(false);
    }
  }, [answer]);

  const historyItem = localStorage.getItem("history") || "[]";
  const history = JSON.parse(historyItem) || [];
  const { prompt, promptToInstall } = useAddToHomescreenPrompt();
  return (
    <div className="App">
      <Background load={load} />
      <header className="App-header">
        <a
          className="App-link"
          href="https://dreaime.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          dre<span>ai</span>me.com
        </a>
        <Lunarphase onClick={() => {}} />
        {
          <div className="install">
            {/* {prompt?.prompt ? (
              <span
                onClick={() => {
                  (promptToInstall as () => Promise<never>)();
                }}
              >
                install app
              </span>
            ) : (
              ""
            )} */}
            <div>
              <button type="submit" onClick={() => changeLanguage("ru-RU")}>
                ru
              </button>
              <button type="submit" onClick={() => changeLanguage("de-DE")}>
                de
              </button>
              <button type="submit" onClick={() => changeLanguage("en")}>
                en
              </button>
            </div>
          </div>
        }
        <div className="ad">1</div>
        {/* <div
          className={`contentSettings${showSettings ? " flipSettings" : ""}`}
          style={{ position: "absolute" }}
        >
          {history
            .toReversed()
            .map(({ image, answer, date }: { [key: string]: string }) => {
              return (
                <div
                  style={{
                    paddingBottom: 100,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ color: "#a0f" }}>{date}</span>
                  <a href={image}>image</a>
                  <span>{answer}</span>
                </div>
              );
            })}
        </div> */}
        <div ref={content} className={`content${showSettings ? " flip" : ""}`}>
          {answer.length > 0 ? (
            <Answer
              image={image}
              answer={answer}
              onClick={async () => {
                // setLoad(true);
                const div = content.current;
                if (div) {
                  div.style.opacity = "0";
                }
                await new Promise((res) => setTimeout(res, 400));
                setAnswer("");
                setImage("");
                if (div) {
                  div.style.opacity = "1";
                }
                setLoad(false);
              }}
            />
          ) : (
            <>
              <div className="App-logocont">
                <h2>{t("header", "Hello, Stranger...")}</h2>
                <p>
                  <span>
                    {t("headerspan1", "How did you sleep last night?")}
                  </span>
                  {/* <span>{t("headerspan2", "Where have you been?")}</span>
                  <span>{t("headerspan3", "What have you seen?")}</span> */}
                </p>
              </div>
              <div className="inputCont">
                <Input
                  setImage={setImage}
                  setAnswer={setAnswer}
                  onClick={async () => {
                    setLoad(true);
                    const div = content.current;
                    if (div) {
                      div.style.opacity = "0";
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="ad">2</div>
      </header>

      <footer>
        <svg
          width="1191"
          height="131"
          viewBox="0 0 1191 131"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M132.797 130.752C132.797 130.257 132.797 129.516 132.797 129.021C132.797 91.4516 120.524 61.0497 95.7317 37.8158C71.1851 14.5818 39.2745 2.96484 0 2.96484V50.1743C24.5466 50.1743 43.6929 57.5894 57.9299 72.4196C71.676 87.0026 78.549 106.035 78.549 129.021C78.549 129.516 78.549 130.257 78.549 130.752H132.797Z"
            fill="#020000"
          />
          <path
            d="M203.982 121.114C211.591 117.406 218.955 115.182 226.319 114.193C233.438 113.204 238.838 110.485 242.766 106.284C246.693 102.082 248.657 96.644 248.657 90.4647C248.657 84.2855 246.938 79.0949 243.256 74.893C239.574 70.9383 234.911 68.9609 228.774 68.9609C226.074 68.9609 221.901 69.9496 216.746 71.927C206.437 75.8817 196.618 84.2855 187.045 97.1383L187.536 93.6779C187.536 86.7572 185.081 81.0723 180.417 76.376C175.753 71.6798 169.862 69.4553 163.235 69.4553C156.607 69.4553 151.207 71.927 146.543 76.6232C141.879 81.3194 139.67 87.2515 139.67 93.9251V130.506H190.236C193.672 127.293 198.336 124.08 203.982 121.114Z"
            fill="#020000"
          />
          <path
            d="M296.032 117.901C303.887 109.992 313.951 106.284 326.715 106.284C340.216 106.284 350.771 110.239 358.626 117.901C362.553 121.856 365.499 126.058 367.708 131.001H414.101C410.173 117.159 402.564 104.801 391.764 93.9255C374.581 76.6236 352.734 67.9727 326.715 67.9727C299.714 67.9727 277.376 77.1179 259.457 95.6557C249.393 106.037 242.275 117.901 237.856 130.754H286.95C289.159 126.058 292.35 121.609 296.032 117.901Z"
            fill="#020000"
          />
          <path
            d="M556.716 84.2859C542.233 73.4104 523.087 67.9727 499.768 67.9727C474.73 67.9727 452.393 74.6462 432.51 87.7462C426.865 91.2066 423.919 96.6444 423.919 103.565C423.919 108.509 425.637 112.958 429.074 116.665C432.51 120.373 436.929 122.103 441.838 122.103C445.274 122.103 448.466 121.114 451.166 119.384C464.175 111.227 477.676 107.273 491.422 107.273C503.45 107.273 513.023 109.992 520.387 115.676C525.787 119.878 528.978 124.822 530.451 131.001H578.808C577.335 109.744 570.217 94.1727 556.716 84.2859Z"
            fill="#020000"
          />
          <path
            d="M716.269 30.4019C712.833 21.2566 706.942 14.0887 699.087 8.40377C690.986 2.96604 682.15 0 672.331 0C662.512 0 653.676 2.71887 645.575 8.40377C637.475 13.8415 631.829 21.2566 628.393 30.4019L589.854 130.753H643.857L672.331 49.1868L700.805 130.753H754.807L716.269 30.4019Z"
            fill="#020000"
          />
          <path
            d="M814.947 27.1904C814.947 19.7753 812.492 13.5961 807.337 8.40553C802.183 3.21496 796.046 0.496094 788.682 0.496094C781.072 0.496094 774.69 3.21496 769.536 8.40553C764.381 13.5961 761.681 20.0225 761.681 27.1904V131.002H814.947V27.1904Z"
            fill="#020000"
          />
          <path
            d="M1095.27 123.089C1095.27 105.045 1090.6 91.2038 1081.52 81.8113C1072.44 72.4189 1059.18 67.7227 1042 67.7227C1029.48 67.7227 1017.46 70.9359 1005.92 77.3623C994.382 83.7887 985.545 91.6981 979.654 101.091C972.044 78.8453 955.353 67.7227 929.333 67.7227C916.815 67.7227 905.032 70.6887 893.986 76.8679C883.186 82.8 875.331 89.2264 870.421 95.6528V93.6755C870.421 86.7547 867.967 81.0698 863.303 76.3736C858.639 71.6774 852.748 69.2057 846.12 69.2057C839.493 69.2057 834.093 71.6774 829.429 76.3736C824.765 81.0698 822.556 87.0019 822.556 93.6755V130.504H871.158C875.331 125.066 880.486 120.37 886.622 116.415C893.495 112.213 900.368 110.236 907.732 110.236C922.706 110.236 931.542 116.909 933.997 130.504H983.581C993.645 117.157 1005.92 110.236 1020.4 110.236C1035.37 110.236 1044.21 116.909 1046.67 130.504H1095.51V123.089H1095.27Z"
            fill="#020000"
          />
          <path
            d="M1160.32 117.651C1168.17 109.742 1178.24 106.034 1191 106.034C1191.25 106.034 1191 67.9703 1191 67.9703C1191 67.9703 1150.25 64.7571 1123.74 95.6533C1114.41 106.529 1106.56 117.899 1102.14 130.751H1150.99C1153.2 126.055 1156.39 121.606 1160.32 117.651Z"
            fill="#020000"
          />
        </svg>
      </footer>
    </div>
  );
}

export default App;
