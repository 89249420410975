import { Moon } from "lunarphase-js";
import "./lunarphase.css";
import { FC } from "react";
const phaseEmoji = Moon.lunarPhaseEmoji();
const phase = Moon.lunarPhase();
interface LunarphaseType {
  onClick: () => void;
}
export const Lunarphase: FC<LunarphaseType> = ({ onClick }) => {
  return (
    <div className="lunarphase">
      <span onClick={onClick}>{phaseEmoji}</span>
    </div>
  );
};
