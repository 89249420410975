import { FC, useEffect, useRef, useState } from "react";
import "./answer.css";
import { title } from "process";

import {
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
interface AnswerType {
  answer: string;
  image: string;
  onClick: () => Promise<void>;
}
export const Answer: FC<AnswerType> = ({ answer, onClick, image }) => {
  const shareUrl = "https://pop-it.su/dreaime/";
  const img = useRef<HTMLImageElement>(null);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (!image || !answer) {
      setLoad(false);
    }
    const imgElement = img.current;
    if (imgElement) {
      imgElement.onload = (e) => {
        setLoad(true);
        console.log("load");
      };
    }
  }, []);
  const { t, i18n } = useTranslation();
  const textTranslate = [
    "You should describe your dream",
    `Sorry, I can't explain this dream. Can you formulate it more specific`,
  ];
  return (
    <div className="answer">
      <div
        className="answerImageCont"
        style={{ width: load ? 300 : 0, opacity: load ? 1 : 0 }}
      >
        <img
          ref={img}
          className="answerImage"
          src={`data:image/jpeg;base64,${image}`}
          alt={"pic"}
        />
        <div className="answerImageSharedButtonCont">
          <FacebookMessengerShareButton
            url={shareUrl}
            appId="521270401588372"
            className="Demo__some-network__share-button"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <XIcon size={32} round />
          </TwitterShareButton>
        </div>
      </div>
      <div className="answerTextCont">
        {textTranslate.includes(answer)
          ? t(answer, answer)
          : answer
              .split(".")
              .map((text, i) => (
                <span style={{ animationDelay: `${i * 1.4}s` }}>{text}.</span>
              ))}
      </div>
      <div className="answerButtonCont">
        <button className="beackButton" onClick={onClick}>
          Dreaime
        </button>
      </div>
    </div>
  );
};
