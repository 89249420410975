import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { json } from "stream/consumers";

export const useGetData = ({
  setImage,
  setAnswer,
  text,
}: {
  setImage: (str: string) => void;
  setAnswer: (str: string) => void;
  text: string;
}) => {
  const { t, i18n } = useTranslation();
  const click = useCallback(async () => {
    const req = async () => {
      const rawResponse = await fetch(
        "https://server2.dreaime.pop-it.su:8383/text",
        {
          mode: "cors",
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          }, // Assuming server expects ",

          body: JSON.stringify({ text }),
        }
      ).then((response) => {
        console.log(response.status);
        return response.json();
      });
      // const reader = await res.json();
      // console.log(reader);
      // let res2 = await reader?.read();
      // let string = new TextDecoder().decode(res2?.value);
      // let str = string;
      // while (!res2?.done) {
      //   res2 = await reader?.read();
      //   let string = new TextDecoder().decode(res2?.value);
      //   str += string;
      // }
      // const json = JSON.parse(str);
      console.log(rawResponse, rawResponse);
      console.log(rawResponse);
      setAnswer(rawResponse.text);
      return rawResponse.text;
    };
    const reqImage = async () => {
      const res = await fetch("https://server2.dreaime.pop-it.su:8383/", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }, // Assuming server expects ",

        body: JSON.stringify({ text }),
      });
      const json = await res.json();
      setImage(json.img);
      return json.img;
    };

    const tryReq = async (req: () => Promise<string>) => {
      try {
        return await req();
      } catch (e) {
        console.error(e);
        await new Promise(async (res) =>
          setTimeout(async () => {
            const a = await tryReq(req);
            return res(a);
          }, 300)
        );
      }
    };
    const answer = await tryReq(req);

    if (
      answer !== "You should describe your dream" &&
      answer !==
        `Sorry, I can't explain this dream. Can you formulate it more specific`
    ) {
      // const image = "";
      const image = await tryReq(reqImage);

      const historyItem = localStorage.getItem("history") || "[]";
      const history = JSON.parse(historyItem) || [];
      if ((history as []).length > 10) {
        (history as []).shift();
      }
      history.push({ answer, image, date: Date() });
      localStorage.setItem("history", JSON.stringify(history));
    }
  }, [i18n.language, setAnswer, setImage, text]);
  return { click };
};
